import { StylesObjectType } from '../../../types/types';

export default function styles(): StylesObjectType {
  return {
    form: {
      width: 375,
    },
    input: {
      padding: '6px 15px !important',
    },
    menu_item: {
      height: '42px !important',
      borderBottom: '1px solid #E8E8E8 !important',
      backgroundColor: '#fff !important',
      textTransform: 'uppercase',
    },
  };
}
