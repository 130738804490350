import {
  errorMessageInvalidChar,
  requiredField,
} from '../../../utils/forms/constants';
import * as yup from 'yup';

export const AddSpecValidation = (
  listOfAtSpec: string[],
  specificationsAliasNames: string[],
): yup.AnyObjectSchema =>
  yup.object().shape({
    atVersion: yup
      .string()
      .required(requiredField)
      .notOneOf(listOfAtSpec, 'At specification exist!')
      .test('isValid', errorMessageInvalidChar, (value) => {
        if (!value) return true;
        return /^\d+\.\d+\.\d+$/.test(value);
      }),
    aliasName: yup
      .string()
      .max(128, 'Max field length is 128 characters')
      .notOneOf(specificationsAliasNames, 'Name should be unique'),
    files: yup
      .mixed()
      .test(
        'isValidFile',
        'Incorrect file format. Only .CSV supported.',
        (value) => {
          if (!value) return true;
          return value.type === 'text/csv';
        },
      ),
  });
