import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import HardwareModelItems from '../../ui/HardwareModelItems';
import { Control, useController } from 'react-hook-form';

import styles from './styles';

const MenuProps = {
  PaperProps: {
    style: {
      height: 160,
      width: 372,
    },
  },
};

const useStyles = makeStyles(styles);

interface Props {
  models: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
}

const SpecificationHardwareModelField: React.FC<Props> = ({
  models,
  control,
}) => {
  const classes = useStyles();
  const { field } = useController({
    name: 'supportedDevices',
    control,
  });

  const renderValue = (selected: string[]): React.ReactChild => (
    <HardwareModelItems items={selected} />
  );
  const array = field.value ? field.value : [];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <FormControl className={classes.form}>
          <InputLabel shrink>Hardware model</InputLabel>
          <Select
            label="Hardware model"
            notched
            multiple
            value={array}
            defaultValue={[]}
            renderValue={renderValue}
            MenuProps={MenuProps}
            inputProps={{ className: classes.input }}
            onChange={field.onChange}
          >
            {models.map((model, index) => (
              <MenuItem key={index} value={model} className={classes.menu_item}>
                <Checkbox checked={array.indexOf(model) > -1} />
                <ListItemText primary={model} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SpecificationHardwareModelField;
