import React, { useState } from 'react';
import { Button } from '@mui/material';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';

import SpecificationVersionFields from '../../../components/forms/SpecificationVersionFields';
import { CreateSpecificationBody } from '../../../redux/specification/interface';
import {
  createSpecification,
  createSpecificationWithFile,
} from '../../../redux/specification/actions';
import { getAtVersionList } from '../../../redux/specs/selectors';
import CustomModal from '../CustomModal';
import { AddSpecValidation } from './config';
import { RootStateType } from '../../../redux/store';
import SpecificationHardwareModelField from '../../forms/SpecificationHardwareModelField';
import PhotoDropZone from '../../../components/formElements/PhotoDropZone';
import Divider from '../../../components/ui/Divider/Divider';

import styles from './styles';
import { prepareFormValues } from './utils';

const useStyles = makeStyles(styles);

const AddSpecModal = ({ handleClose }: TProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const atVersions = useSelector(getAtVersionList);
  const allAliasNames: string[] = atVersions
    .filter((item) => item.aliasName)
    .map((item) => item.aliasName as string);

  const hardwareModelNames = useSelector(
    (state: RootStateType) => state.devices.hardwareModelNames,
  );

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<CreateSpecificationBody>({
    mode: 'onChange',
    resolver: yupResolver(
      AddSpecValidation(
        atVersions.map((item) => item.atVersion),
        allAliasNames,
      ),
    ),
  });

  const { files } = watch();

  const submit = (values: CreateSpecificationBody) => {
    setIsSubmiting(true);
    if (values.files) {
      dispatch(
        createSpecificationWithFile({
          data: prepareFormValues({
            ...values,
            supportedDevices: values.supportedDevices || [],
          }),
        }),
      )
        .promise?.then(handleClose)
        .finally(() => setIsSubmiting(false));
    } else {
      dispatch(
        createSpecification({
          data: { ...values, supportedDevices: values.supportedDevices || [] },
        }),
      )
        .promise?.then(handleClose)
        .finally(() => setIsSubmiting(false));
    }
  };

  return (
    <CustomModal
      headerText="Add New Specification"
      buttonsBlock={
        <div className={classes.buttons}>
          <Button
            variant="contained"
            disabled={isSubmiting}
            onClick={handleSubmit(submit)}
            color="success"
            fullWidth
          >
            Save
          </Button>
          <div className={classes.divider} />
          <Button
            fullWidth
            color="error"
            onClick={handleClose}
            variant="contained"
          >
            Cancel
          </Button>
        </div>
      }
    >
      <SpecificationVersionFields control={control} />
      <SpecificationHardwareModelField
        models={hardwareModelNames}
        control={control}
      />
      <Divider />
      <Typography className={classes.fileText}>
        You can upload your AT Specification as{' '}
        <span className={classes.boldText}>.CSV file.</span>
      </Typography>
      <div className={classes.file}>
        <div className={classes.dropZone}>
          <PhotoDropZone
            name="files"
            control={control}
            label="UPLOAD FILE"
            disabled={!!files || (errors && !!errors.files)}
          />
        </div>
        {files && <div>{files.name}</div>}
        {files && (
          <IconButton
            className={classes.delete}
            onClick={() => {
              setValue('files', undefined);
              clearErrors('files');
            }}
          >
            <DeleteIcon htmlColor="#942626" />
          </IconButton>
        )}
      </div>
      {errors && errors.files && (
        <div className={classes.errorState}>{errors.files.message}</div>
      )}
    </CustomModal>
  );
};

type TProps = {
  handleClose: () => unknown;
};

export default AddSpecModal;
