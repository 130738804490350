import { createAction } from '@reduxjs/toolkit';
import API_ENDPOINTS from '../../api/endpoints';
import asyncActionCreator from '../../utils/redux/asyncActionCreator';
import {
  FetchAtCommandsResponse,
  FetchSpecListParams,
  FetchSpecsListMods,
  FetchSpecsListResponse,
} from './interface';
import types from './types';
import { SpecificationType } from '../../types/spec';

export const [fetchSpecListRequest, fetchSpecListRequestActions] =
  asyncActionCreator<
    FetchSpecsListResponse,
    undefined,
    FetchSpecListParams,
    undefined
  >(
    types.FETCH_SPEC_LIST_REQUEST,
    'GET',
    API_ENDPOINTS.SPECIFICATIONS.GET_SPEC_LIST,
  );

export const [fetchSensorSpecListRequest, fetchSensorSpecListRequestActions] =
  asyncActionCreator<
    FetchSpecsListResponse,
    undefined,
    FetchSpecListParams,
    undefined
  >(
    types.FETCH_SENSOR_SPEC_LIST_REQUEST,
    'GET',
    API_ENDPOINTS.SPECIFICATIONS.GET_SPEC_LIST,
  );

export const fetchSpecList = createAction<FetchSpecsListMods>(
  types.FETCH_SPEC_LIST,
);

export const fetchSensorSpecList = createAction<FetchSpecsListMods>(
  types.FETCH_SENSOR_SPEC_LIST,
);

export const [fetchAtVersionList, fetchAtVersionListActions] =
  asyncActionCreator<FetchAtCommandsResponse, undefined, undefined, undefined>(
    types.FETCH_AT_VERSIONS_LIST,
    'GET',
    API_ENDPOINTS.GET_LIST_AT_VERSIONS,
  );

export const [fetchSensorSpecVersionList, fetchSensorSpecVersionListActions] =
  asyncActionCreator<
    FetchAtCommandsResponse,
    undefined,
    { type?: SpecificationType },
    undefined
  >(
    types.FETCH_SENSOR_SPEC_VERSIONS_LIST,
    'GET',
    API_ENDPOINTS.GET_LIST_AT_VERSIONS,
  );
